// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.invoice-a4 table th, 
.invoice-a4 table td {
  border: 2px solid #000;
  padding: 8px;
  text-align: center;
  vertical-align: middle;
}

.invoice-a4 table thead th {
  top: 0;
  background-color: #f5f5f5;
  z-index: 10;
}

.invoice-a4 .ant-table-container {
  border: 2px solid #000;
}


.invoice-a4 table td:last-child, 
.invoice-a4 table th:last-child {
  border-right: 2px solid #000;
}
.invoice-a4{
  padding-left: 30px;
  padding-right: 20px;
}

/* .ant-table-wrapper .ant-table th, 
.ant-table-wrapper .ant-table td {
    padding: 2px;
    border-inline-end: 1px solid #f0f0f0; 
} */

/* .ant-table-wrapper .ant-table th, 
.ant-table-wrapper .ant-table td {
    padding: 2px !important; 
    border-inline-end: 1px solid #f0f0f0 !important;
} */


.ant-table-wrapper .ant-table thead th,
.ant-table-wrapper .ant-table tbody td,
.ant-table-wrapper .ant-table tfoot td {
    padding: 5px !important;
}


/* .ant-table-wrapper .ant-table-summary td,
.ant-table-wrapper .ant-table-summary th {
    padding: 2px !important;
} */




/* @media print {
  .invoice-header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    background: white;
    padding-bottom: 20px;
  }
  .invoice-body {
    margin-top: 200px; 
    position: fixed;
    padding: 1%;
    margin-left: -10px;
  }

  .ant-modal-footer, 
  .ant-modal-close-x {
    display: none !important;
  }

} */
`, "",{"version":3,"sources":["webpack://./src/css/Invoice.css"],"names":[],"mappings":"AAAA;;EAEE,sBAAsB;EACtB,YAAY;EACZ,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,MAAM;EACN,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,sBAAsB;AACxB;;;AAGA;;EAEE,4BAA4B;AAC9B;AACA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;;;;GAIG;;AAEH;;;;GAIG;;;AAGH;;;IAGI,uBAAuB;AAC3B;;;AAGA;;;GAGG;;;;;AAKH;;;;;;;;;;;;;;;;;;;;;GAqBG","sourcesContent":[".invoice-a4 table th, \n.invoice-a4 table td {\n  border: 2px solid #000;\n  padding: 8px;\n  text-align: center;\n  vertical-align: middle;\n}\n\n.invoice-a4 table thead th {\n  top: 0;\n  background-color: #f5f5f5;\n  z-index: 10;\n}\n\n.invoice-a4 .ant-table-container {\n  border: 2px solid #000;\n}\n\n\n.invoice-a4 table td:last-child, \n.invoice-a4 table th:last-child {\n  border-right: 2px solid #000;\n}\n.invoice-a4{\n  padding-left: 30px;\n  padding-right: 20px;\n}\n\n/* .ant-table-wrapper .ant-table th, \n.ant-table-wrapper .ant-table td {\n    padding: 2px;\n    border-inline-end: 1px solid #f0f0f0; \n} */\n\n/* .ant-table-wrapper .ant-table th, \n.ant-table-wrapper .ant-table td {\n    padding: 2px !important; \n    border-inline-end: 1px solid #f0f0f0 !important;\n} */\n\n\n.ant-table-wrapper .ant-table thead th,\n.ant-table-wrapper .ant-table tbody td,\n.ant-table-wrapper .ant-table tfoot td {\n    padding: 5px !important;\n}\n\n\n/* .ant-table-wrapper .ant-table-summary td,\n.ant-table-wrapper .ant-table-summary th {\n    padding: 2px !important;\n} */\n\n\n\n\n/* @media print {\n  .invoice-header {\n    position: fixed;\n    top: 0;\n    width: 100%;\n    z-index: 1000;\n    background: white;\n    padding-bottom: 20px;\n  }\n  .invoice-body {\n    margin-top: 200px; \n    position: fixed;\n    padding: 1%;\n    margin-left: -10px;\n  }\n\n  .ant-modal-footer, \n  .ant-modal-close-x {\n    display: none !important;\n  }\n\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
