import React, { useEffect, useState } from "react";
import {
  Table,
  Modal,
  Button,
  DatePicker,
  Select,
  Row,
  Col,
  Spin,
  Input,
} from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Invoice from "../components/invoice";
import dayjs from "dayjs";
import { fetchCorporateSales, fetchBusinessLocations } from "../api/api";
import { useSelector, useDispatch } from "react-redux";
import {
  setDateRange,
  setSelectedLocation,
} from "../redux/corporateSalesSlice";

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Search } = Input;

const CorporateSales = () => {
  const dispatch = useDispatch();
  const reduxDateRange = useSelector((state) => state.corporateSales.dateRange);
  const reduxSelectedLocation = useSelector(
    (state) => state.corporateSales.selectedLocation
  );
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedLocation, setSelectedLocationState] = useState("all");
  const [searchText, setSearchText] = useState("");
  const [businessLocations, setBusinessLocations] = useState([]);
  const [dateRange, setDateRangeState] = useState(() => {
    const today = dayjs();
    const yesterday = dayjs().subtract(1, "days");
    return reduxDateRange || [yesterday, today];
  });

  useEffect(() => {
    if (!reduxDateRange) {
      dispatch(setDateRange(dateRange));
    }
  }, [reduxDateRange, dateRange, dispatch]);

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const locations = await fetchBusinessLocations();
        setBusinessLocations(locations);
      } catch (err) {
        console.error("Failed to fetch locations:", err);
      }
    };

    fetchLocations();
  }, []);

  useEffect(() => {
    const fetchSalesData = async () => {
      setLoading(true);
      setError(null);

      try {
        const [startDate, endDate] = dateRange;
        const fetchedData = await fetchCorporateSales(
          startDate.format("YYYY-MM-DD"),
          endDate.format("YYYY-MM-DD"),
          "desc"
        );
        setData(fetchedData);
        setFilteredData(
          fetchedData.filter(
            (item) =>
              reduxSelectedLocation === "all" ||
              item.location_id.toString() === reduxSelectedLocation
          )
        );
      } catch (err) {
        setError("Failed to fetch corporate sales data.");
      } finally {
        setLoading(false);
      }
    };

    fetchSalesData();
  }, [dateRange]);

  useEffect(() => {
    let filtered = data;

    if (reduxSelectedLocation !== "all") {
      filtered = filtered.filter(
        (item) => item.location_id.toString() === reduxSelectedLocation
      );
    }

    if (searchText) {
      filtered = filtered.filter(
        (item) =>
          item.invoice_no.toLowerCase().includes(searchText.toLowerCase()) ||
          item.contact?.name
            ?.toLowerCase()
            .includes(searchText.toLowerCase()) ||
          item.contact?.mobile
            ?.toLowerCase()
            .includes(searchText.toLowerCase()) ||
          item.final_total.toLowerCase().includes(searchText.toLowerCase()) ||
          item.payment_status
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          item.payment_lines?.some((line) =>
            line.method?.toLowerCase().includes(searchText.toLowerCase())
          )
      );
    }

    setFilteredData(filtered);
  }, [reduxSelectedLocation, searchText, data]);

  const handleLocationChange = (value) => {
    if (value) {
      setSelectedLocationState(value);
      dispatch(setSelectedLocation(value));
    } else {
      console.error("Invalid location value");
    }
  };

  const locationMapping = React.useMemo(() => {
    return businessLocations.reduce((acc, location) => {
      acc[location.id] = location.name;
      return acc;
    }, {});
  }, [businessLocations]);

  const columns = [
    {
      title: "Date",
      dataIndex: "transaction_date",
      key: "transaction_date",
      render: (text) => (text ? new Date(text).toLocaleString() : ""),
    },
    {
      title: "Invoice No",
      dataIndex: "invoice_no",
      key: "invoice_no",
    },
    {
      title: "Customer Name",
      dataIndex: "contact",
      key: "customer_name",
      render: (text) => text?.name || "",
    },
    {
      title: "Contact Number",
      dataIndex: "contact",
      key: "contact_number",
      render: (text) => text?.mobile || "",
    },
    {
      title: "Location",
      dataIndex: "location_id",
      key: "location",
      render: (text) => locationMapping[text] || "Unknown Location",
    },
    {
      title: "Payment Status",
      dataIndex: "payment_status",
      key: "payment_status",
    },
    {
      title: "Payment Method",
      dataIndex: "payment_lines",
      key: "method",
      render: (text) =>
        Array.isArray(text) && text.length > 0
          ? text[0]?.method === "bank_transfer"
            ? "Bank Transfer"
            : text[0]?.method
          : "",
    },
    {
      title: "Total Amount",
      dataIndex: "final_total",
      key: "final_total",
      render: (text) => {
        return text ? parseFloat(text).toFixed(2) : "";
      },
    },
    {
      title: "Total Paid",
      dataIndex: "payment_lines",
      key: "amount",
      render: (text, record) => {
        if (Array.isArray(text) && text.length > 0) {
          return parseFloat(text[0].amount).toFixed(2) || "";
        }
        return "";
      },
    },
    // {
    //   title: "Sales Due",
    //   dataIndex: "payment_lines",
    //   key: "sales_due",
    //   render: (text, record) => {
    //     if (Array.isArray(text) && text.length > 0) {
    //       const paidAmount = text[0]?.amount || 0;
    //       return record.final_total - paidAmount || 0;
    //     }
    //     return record.final_total || "";
    //   },
    // },
    // {
    //   title: "Sales Return Due",
    //   dataIndex: "",
    //   key: "sales_return_due",
    //   render: () => "",
    // },
    {
      title: "View Invoice",
      key: "view_invoice",
      render: (text, record) => (
        <Button
          type="primary"
          onClick={() => {
            setSelectedBooking(record);
            setIsModalVisible(true);
          }}
        >
          View Invoice
        </Button>
      ),
    },
  ];

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleDateChange = (dates) => {
    setDateRangeState(dates);
    dispatch(setDateRange(dates));
  };

  return (
    <>
      <Row justify="space-between" align="middle" style={{ marginBottom: 20 }}>
        <Col>
          <h1>Corporate Sales</h1>
        </Col>
        <Col>
          <Button
            type="primary"
            icon={<SettingOutlined />}
            onClick={() => navigate("/corporate-sales/settings")}
          >
            Invoice Settings
          </Button>
        </Col>
      </Row>

      <Row gutter={16} align="middle" style={{ marginBottom: 20 }}>
        <Col>
          <h3 style={{ display: "inline", marginRight: 10 }}>
            Select Location:{" "}
          </h3>
          <Select
            value={reduxSelectedLocation}
            onChange={handleLocationChange}
            style={{ width: 200 }}
          >
            <Option value="all">All Locations</Option>
            {businessLocations.map((location) => (
              <Option key={location.id} value={location.id.toString()}>
                {location.name}
              </Option>
            ))}
          </Select>
        </Col>
        <Col>
          <h3 style={{ display: "inline", marginRight: 10 }}>Date Range: </h3>
          <RangePicker
            defaultValue={dateRange}
            onChange={handleDateChange}
            format="YYYY-MM-DD"
          />
        </Col>
        <Col flex="auto" style={{ textAlign: "right" }}>
          <h3 style={{ display: "inline", marginRight: 10 }}>Search: </h3>
          <Search
            placeholder="Search by invoice, customer name, or contact"
            onSearch={(value) => setSearchText(value)}
            onChange={(e) => setSearchText(e.target.value)}
            style={{ width: 300 }}
          />
        </Col>
      </Row>

      <div style={{ marginTop: "30px" }}>
        {loading ? (
          <Spin size="large" />
        ) : error ? (
          <p>{error}</p>
        ) : (
          <Table
            columns={columns}
            dataSource={filteredData}
            rowKey="id"
            pagination={{
              pageSize: 15,
            }}
          />
        )}

        <Invoice
          selectedBooking={selectedBooking}
          visible={isModalVisible}
          onClose={handleCancel}
        />
      </div>
    </>
  );
};

export default CorporateSales;
