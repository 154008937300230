import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button } from "antd";
import axios from "axios";
import {login} from "../api/api"

const Login = () => {
  const navigate = useNavigate();
  const [formError, setFormError] = useState(null);

  const onFinish = async (values) => {
    try {
      const { email, password } = values;
      const userData = await login(email, password);
      const { token } = userData;
      localStorage.setItem("token", token);
      navigate("/dashboard");
    } catch (error) {
      console.error("Error during login:", error);
      if (error.response) {
        setFormError(
          error.response.data.error || "An unexpected error occurred"
        );
      } else {
        setFormError("An unexpected error occurred");
      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <br />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <div
          style={{
            maxWidth: "600px",
            width: "100%",
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[
                { required: true, message: "Please Enter Your Email Address" },
                {
                  type: "email",
                  message: "Please enter a valid email address",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: "Please Enter A Password" }]}
            >
              <Input.Password />
            </Form.Item>

            {formError && (
              <Form.Item>
                <div style={{ color: "red" }}>{formError}</div>
              </Form.Item>
            )}

            <Form.Item wrapperCol={{ span: 24 }}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "45%", marginRight: "10px" }}
                >
                  Submit
                </Button>
                <Button
                  type="primary"
                  onClick={() => navigate("/")}
                  style={{ width: "45%" }}
                >
                  Go Back
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};

export default Login;
