import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/navbar";
import "../css/dashboard.css";

const Dashboard = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  return (
    <>
      <h1>Dashboard</h1>
    </>
  );
};

export default Dashboard;
