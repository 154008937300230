import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table, Spin, DatePicker, Button, Select } from "antd";
import * as XLSX from "xlsx";
import dayjs from "dayjs";
import Navbar from "../components/navbar";
import {fetchGstPurchaseReport} from "../api/api"

const { RangePicker } = DatePicker;
const { Option } = Select;

const roundToTwo = (num) => Number(Math.round(num * 100) / 100).toFixed(2);

const GstPurchaseReport = () => {
  const token = localStorage.getItem('token');
  const isLoggedIn = token ? true : false;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dates, setDates] = useState([null, null]);
  const [pageSize, setPageSize] = useState(10);

  const fetchData = async (startDate, endDate) => {
    setLoading(true);
    try {
      const formattedStartDate = startDate
        ? dayjs(startDate).format("YYYY-MM-DD")
        : "";
      const formattedEndDate = endDate
        ? dayjs(endDate).format("YYYY-MM-DD")
        : "";
      const response = await fetchGstPurchaseReport(formattedStartDate, formattedEndDate);

      const groupedData = response.data.reduce((acc, item) => {
        const key = `${item.supplier}-${item.supplier_business_name}`;
        if (!acc[key]) {
          acc[key] = {
            key: key,
            supplier: item.supplier,
            supplier_business_name: item.supplier_business_name,
            gstin: item.gstin,
            total_amount: 0,
            taxable_amount_18: 0,
            taxable_amount_12: 0,
            taxable_amount_5: 0,
            cgst_9: 0,
            sgst_9: 0,
            cgst_6: 0,
            sgst_6: 0,
            cgst_2_5: 0,
            sgst_2_5: 0,
            transactions: [],
          };
        }

        acc[key].total_amount = roundToTwo(
          parseFloat(acc[key].total_amount || 0) +
            parseFloat(item.line_total || 0)
        );

        if (item.tax_percent === 18) {
          const item_tax = parseFloat(item.item_tax || 0);
          const purchase_qty = parseFloat(item.purchase_qty);

          acc[key].taxable_amount_18 = roundToTwo(
            parseFloat(acc[key].taxable_amount_18 || 0) +
              item_tax * purchase_qty
          );
          acc[key].cgst_9 = roundToTwo(
            parseFloat(acc[key].cgst_9 || 0) + (item_tax * purchase_qty) / 2
          );
          acc[key].sgst_9 = roundToTwo(
            parseFloat(acc[key].sgst_9 || 0) + (item_tax * purchase_qty) / 2
          );
        } else if (item.tax_percent === 12) {
          const item_tax = parseFloat(item.item_tax || 0);
          const purchase_qty = parseFloat(item.purchase_qty);

          acc[key].taxable_amount_12 = roundToTwo(
            parseFloat(acc[key].taxable_amount_12 || 0) +
              item_tax * purchase_qty
          );
          acc[key].cgst_6 = roundToTwo(
            parseFloat(acc[key].cgst_6 || 0) + (item_tax * purchase_qty) / 2
          );
          acc[key].sgst_6 = roundToTwo(
            parseFloat(acc[key].sgst_6 || 0) + (item_tax * purchase_qty) / 2
          );
        } else if (item.tax_percent === 5) {
          const item_tax = parseFloat(item.item_tax || 0);
          const purchase_qty = parseFloat(item.purchase_qty);

          acc[key].taxable_amount_5 = roundToTwo(
            parseFloat(acc[key].taxable_amount_5 || 0) + item_tax * purchase_qty
          );
          acc[key].cgst_2_5 = roundToTwo(
            parseFloat(acc[key].cgst_2_5 || 0) + (item_tax * purchase_qty) / 2
          );
          acc[key].sgst_2_5 = roundToTwo(
            parseFloat(acc[key].sgst_2_5 || 0) + (item_tax * purchase_qty) / 2
          );
        }

        acc[key].transactions.push(item);

        return acc;
      }, {});

      setData(Object.values(groupedData));
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(null, null);
  }, []);

  const handleDateChange = (values) => {
    if (values && values.length === 2) {
      const [startDate, endDate] = values;
      setDates([startDate, endDate]);
      fetchData(startDate, endDate);
    } else {
      setDates([null, null]);
      fetchData(null, null);
    }
  };

  const exportToExcel = () => {
    const formattedData = data.map((item) => ({
      ...item,
      total_amount: parseFloat(item.total_amount).toFixed(2),
      taxable_amount_18: parseFloat(item.taxable_amount_18).toFixed(2),
      taxable_amount_12: parseFloat(item.taxable_amount_12).toFixed(2),
      taxable_amount_5: parseFloat(item.taxable_amount_5).toFixed(2),
      cgst_9: parseFloat(item.cgst_9).toFixed(2),
      sgst_9: parseFloat(item.sgst_9).toFixed(2),
      cgst_6: parseFloat(item.cgst_6).toFixed(2),
      sgst_6: parseFloat(item.sgst_6).toFixed(2),
      cgst_2_5: parseFloat(item.cgst_2_5).toFixed(2),
      sgst_2_5: parseFloat(item.sgst_2_5).toFixed(2),
    }));

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "GST Purchase Report");
    XLSX.writeFile(workbook, "GST_Purchase_Report.xlsx");
  };

  const columns = [
    {
      title: "Party Name",
      key: "party_name",
      render: (text, record) => {
        const { supplier, supplier_business_name, gstin } = record;
        return `${supplier || ""} ${
          supplier_business_name ? `${supplier_business_name}` : ""
        } ${gstin ? `(GSTIN: ${gstin})` : ""}`;
      },
    },
    {
      title: "Total Invoice Amount",
      dataIndex: "total_amount",
      key: "total_amount",
    },
    {
      title: "Taxable Amount 18%",
      dataIndex: "taxable_amount_18",
      key: "taxable_amount_18",
    },
    {
      title: "Taxable Amount 12%",
      dataIndex: "taxable_amount_12",
      key: "taxable_amount_12",
    },
    {
      title: "Taxable Amount 5%",
      dataIndex: "taxable_amount_5",
      key: "taxable_amount_5",
    },
    { title: "CGST 9%", dataIndex: "cgst_9", key: "cgst_9" },
    { title: "SGST 9%", dataIndex: "sgst_9", key: "sgst_9" },
    { title: "CGST 6%", dataIndex: "cgst_6", key: "cgst_6" },
    { title: "SGST 6%", dataIndex: "sgst_6", key: "sgst_6" },
    { title: "CGST 2.5%", dataIndex: "cgst_2_5", key: "cgst_2_5" },
    { title: "SGST 2.5%", dataIndex: "sgst_2_5", key: "sgst_2_5" },
  ];

  const expandedRowRender = (record) => {
    const detailedColumns = [
      { title: "GSTIN", dataIndex: "gstin", key: "gstin" },
      {
        title: "Invoice Date",
        dataIndex: "transaction_date",
        key: "transaction_date",
      },
      { title: "Invoice No", dataIndex: "ref_no", key: "ref_no" },
      {
        title: "Total Invoice Amount",
        dataIndex: "line_total",
        key: "line_total",
      },
    ];

    return (
      <Table
        columns={detailedColumns}
        dataSource={record.transactions || []}
        pagination={false}
        rowKey="transaction_id"
      />
    );
  };

  return (
    <>
      <Navbar isLoggedIn={isLoggedIn} className="navbar" />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: 16,
        }}
      >
        <RangePicker
          value={dates}
          onChange={handleDateChange}
          format="YYYY-MM-DD"
          style={{ marginRight: 8 }}
        />
        <Select
          defaultValue={10}
          style={{ width: 120, marginRight: 8 }}
          onChange={(value) => setPageSize(value)}
        >
          <Option value={10}>10</Option>
          <Option value={20}>20</Option>
          <Option value={50}>50</Option>
          <Option value={100}>100</Option>
          <Option value={500}>500</Option>
          <Option value={1000}>1000</Option>
        </Select>
        <Button
          type="primary"
          onClick={() => fetchData(dates[0], dates[1])}
          style={{ marginRight: 8 }}
        >
          Filter
        </Button>
        <Button type="primary" onClick={exportToExcel}>
          Export to Excel
        </Button>
      </div>
      {loading ? (
        <Spin size="large" />
      ) : (
        <Table
          columns={columns}
          expandable={{
            expandedRowRender,
            rowExpandable: (record) =>
              record.transactions && record.transactions.length > 0,
          }}
          dataSource={data}
          rowKey="key"
          pagination={{
            pageSize: pageSize,
            pageSizeOptions: ["10", "20", "50", "100", "500", "1000"],
            showSizeChanger: true,
          }}
        />
      )}
    </>
  );
};

export default GstPurchaseReport;
