import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table, Spin, DatePicker, Button, Select } from "antd";
import * as XLSX from "xlsx";
import dayjs from "dayjs";
import Navbar from "../components/navbar";
import {fetchGstSalesReport} from "../api/api"

const { RangePicker } = DatePicker;
const { Option } = Select;

const roundToTwo = (num) => Number(Math.round(num * 100) / 100).toFixed(2);

const GstSalesB2CReport = () => {
  const token = localStorage.getItem("token");
  const isLoggedIn = token ? true : false;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dates, setDates] = useState([null, null]);
  const [pageSize, setPageSize] = useState(10);

  const fetchData = async (startDate, endDate) => {
    setLoading(true);
    try {
      const formattedStartDate = startDate
        ? dayjs(startDate).format("YYYY-MM-DD")
        : "";
      const formattedEndDate = endDate
        ? dayjs(endDate).format("YYYY-MM-DD")
        : "";

      const response = await fetchGstSalesReport(formattedStartDate, formattedEndDate);

      const transformedData = response?.data.map((item, index) => ({
        key: item.transaction_id,
        invoice_no: item.invoice_no,
        transaction_date: dayjs(item.transaction_date).format("YYYY-MM-DD"),
        customer: item.customer || "Unknown",
        gstin: item.tax_number || "Not Provided",
        total_amount: roundToTwo(parseFloat(item.line_total)),
        taxable_amount_18:
          item.tax_percent === 18
            ? roundToTwo(parseFloat(item.item_tax))
            : "0.00",
        taxable_amount_12:
          item.tax_percent === 12
            ? roundToTwo(parseFloat(item.item_tax))
            : "0.00",
        taxable_amount_5:
          item.tax_percent === 5
            ? roundToTwo(parseFloat(item.item_tax))
            : "0.00",
        cgst_9:
          item.tax_percent === 18
            ? roundToTwo(parseFloat(item.item_tax) / 2)
            : "0.00",
        sgst_9:
          item.tax_percent === 18
            ? roundToTwo(parseFloat(item.item_tax) / 2)
            : "0.00",
        cgst_6:
          item.tax_percent === 12
            ? roundToTwo(parseFloat(item.item_tax) / 2)
            : "0.00",
        sgst_6:
          item.tax_percent === 12
            ? roundToTwo(parseFloat(item.item_tax) / 2)
            : "0.00",
        cgst_2_5:
          item.tax_percent === 5
            ? roundToTwo(parseFloat(item.item_tax) / 2)
            : "0.00",
        sgst_2_5:
          item.tax_percent === 5
            ? roundToTwo(parseFloat(item.item_tax) / 2)
            : "0.00",
      }));

      setData(transformedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(null, null);
  }, []);

  const handleDateChange = (values) => {
    if (values && values.length === 2) {
      const [startDate, endDate] = values;
      setDates([startDate, endDate]);
      fetchData(startDate, endDate);
    } else {
      setDates([null, null]);
      fetchData(null, null);
    }
  };

  const exportToExcel = () => {
    const formattedData = data.map((item) => ({
      ...item,
      total_amount: parseFloat(item.total_amount || 0).toFixed(2),
      taxable_amount_18: parseFloat(item.taxable_amount_18 || 0).toFixed(2),
      taxable_amount_12: parseFloat(item.taxable_amount_12 || 0).toFixed(2),
      taxable_amount_5: parseFloat(item.taxable_amount_5 || 0).toFixed(2),
      cgst_9: parseFloat(item.cgst_9 || 0).toFixed(2),
      sgst_9: parseFloat(item.sgst_9 || 0).toFixed(2),
      cgst_6: parseFloat(item.cgst_6 || 0).toFixed(2),
      sgst_6: parseFloat(item.sgst_6 || 0).toFixed(2),
      cgst_2_5: parseFloat(item.cgst_2_5 || 0).toFixed(2),
      sgst_2_5: parseFloat(item.sgst_2_5 || 0).toFixed(2),
    }));

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "GST Sales Report");
    XLSX.writeFile(workbook, "GST_Sales_B2C_Report.xlsx");
  };

  const columns = [
    { title: "Invoice No", dataIndex: "invoice_no", key: "invoice_no" },
    {
      title: "Invoice Date",
      dataIndex: "transaction_date",
      key: "transaction_date",
    },
    { title: "Customer", key: "customer", dataIndex: "customer" },
    { title: "GSTIN", dataIndex: "gstin", key: "gstin" },
    {
      title: "Total Invoice Amount",
      dataIndex: "total_amount",
      key: "total_amount",
    },
    {
      title: "Taxable Amount 18%",
      dataIndex: "taxable_amount_18",
      key: "taxable_amount_18",
    },
    {
      title: "Taxable Amount 12%",
      dataIndex: "taxable_amount_12",
      key: "taxable_amount_12",
    },
    {
      title: "Taxable Amount 5%",
      dataIndex: "taxable_amount_5",
      key: "taxable_amount_5",
    },
    { title: "CGST 9%", dataIndex: "cgst_9", key: "cgst_9" },
    { title: "SGST 9%", dataIndex: "sgst_9", key: "sgst_9" },
    { title: "CGST 6%", dataIndex: "cgst_6", key: "cgst_6" },
    { title: "SGST 6%", dataIndex: "sgst_6", key: "sgst_6" },
    { title: "CGST 2.5%", dataIndex: "cgst_2_5", key: "cgst_2_5" },
    { title: "SGST 2.5%", dataIndex: "sgst_2_5", key: "sgst_2_5" },
  ];

  return (
    <>
      <Navbar isLoggedIn={isLoggedIn} className="navbar" />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: 16,
        }}
      >
        <RangePicker
          value={dates}
          onChange={handleDateChange}
          format="YYYY-MM-DD"
        />
        <Button
          type="primary"
          onClick={exportToExcel}
          style={{ marginLeft: 16 }}
        >
          Export to Excel
        </Button>
        <Select
          defaultValue={10}
          style={{ marginLeft: 16, width: 120 }}
          onChange={(value) => setPageSize(value)}
        >
          <Option value={10}>10</Option>
          <Option value={20}>20</Option>
          <Option value={50}>50</Option>
          <Option value={100}>100</Option>
          <Option value={500}>500</Option>
          <Option value={1000}>1000</Option>
        </Select>
      </div>
      {loading ? (
        <Spin />
      ) : (
        <Table
          columns={columns}
          dataSource={data}
          rowKey="key" 
          pagination={{ pageSize }}
        />
      )}
    </>
  );
};

export default GstSalesB2CReport;
