import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./css/home.css";
import Navbar from "./components/navbar";

const Home = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const isLoggedIn = token ? true : false;
  return (
    <>
      <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Navbar isLoggedIn={isLoggedIn} />
      </div>
    </>
  );
};

export default Home;
