import React, { useEffect, useState } from "react";
import { Table, Spin, message, Radio, Input, Button } from "antd";
import { fetchPaymentAccounts, fetchInvoiceSettings, updateInvoiceSetting } from "../api/api";

const InvoiceSettings = () => {
  const [loading, setLoading] = useState(false);
  const [paymentAccounts, setPaymentAccounts] = useState([]);
  const [invoiceSettings, setInvoiceSettings] = useState([]);
  const [error, setError] = useState(null);
  const [selectedPayment, setSelectedPayment] = useState(null);

  // Fetch payment accounts and invoice settings from the API
  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      setError(null);

      try {
        const accounts = await fetchPaymentAccounts();
        const settings = await fetchInvoiceSettings();
        setPaymentAccounts(accounts);
        setInvoiceSettings(settings);
        setSelectedPayment(settings[0]?.invoice_bank_id || null); // Default selected payment
      } catch (err) {
        console.error("Error loading data:", err.message);
        setError("Failed to load data.");
        message.error("Failed to load data.");
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);

  // Handle radio button change for payment accounts
  const handleRadioChange = (e, record) => {
    setSelectedPayment(record.id);
    const updatedSettings = invoiceSettings.map((setting) => ({
      ...setting,
      invoice_bank_id: record.id,
    }));
    setInvoiceSettings(updatedSettings);
  };

  // Handle input changes for invoice settings
  const handleInputChange = (id, value) => {
    setInvoiceSettings((prevSettings) =>
      prevSettings.map((setting) =>
        setting.id === id ? { ...setting, invoice_note: value } : setting
      )
    );
  };

  // Handle saving the updated invoice setting
  const handleSaveSetting = async (id) => {
    const settingToUpdate = invoiceSettings.find((setting) => setting.id === id);

    try {
      await updateInvoiceSetting(id, {
        invoice_bank_id: settingToUpdate.invoice_bank_id,
        invoice_note: settingToUpdate.invoice_note,
      });
      message.success("Setting updated successfully!");
    } catch (err) {
      console.error("Error updating setting:", err.message);
      message.error("Failed to update setting.");
    }
  };

  // Table columns for payment accounts
  const paymentColumns = [
    {
      title: "Account Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Account Number",
      dataIndex: "account_number",
      key: "account_number",
    },
    {
      title: "Note",
      dataIndex: "note",
      key: "note",
    },
    {
      title: "Select Payment",
      dataIndex: "select_payment",
      key: "select_payment",
      render: (text, record) => (
        <Radio
          value={record.id}
          checked={selectedPayment === record.id}
          onChange={(e) => handleRadioChange(e, record)}
        />
      ),
    },
  ];

  // Render component
  return (
    <div>
      <h1>Invoice Settings</h1>
      {loading ? (
        <Spin size="large" />
      ) : error ? (
        <p>{error}</p>
      ) : (
        <>
          <h2>Bank Details</h2>
          <Table
            dataSource={paymentAccounts}
            columns={paymentColumns}
            rowKey="id"
            pagination={{ pageSize: 10 }}
          />

          {/* <h2>Note</h2> */}
          {invoiceSettings.map((setting) => (
            <div key={setting.id} style={{ marginBottom: "16px" }}>
              {/* <Input.TextArea
                rows={4}
                value={setting.invoice_note}
                onChange={(e) => handleInputChange(setting.id, e.target.value)}
                style={{ width: "100%", marginBottom: "8px" }}
              /> */}
              <Button type="primary" onClick={() => handleSaveSetting(setting.id)}>
                Save
              </Button>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default InvoiceSettings;
