// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    min-height: 100vh; /* Ensure the container takes up full height */
    box-sizing: border-box; /* Include padding and border in element's total width and height */
  }
  
  .navbar {
    width: 100%;
    margin-bottom: 20px; /* Add space between navbar and content */
  }
  
  h1 {
    font-size: 2rem;
    margin-bottom: 20px;
    text-align: center; /* Center-align the heading */
  }
  
  .button-container {
    display: flex;
    flex-direction: column; /* Stack buttons vertically on small screens */
    gap: 10px; /* Add space between buttons */
  }
  
  .button-container .ant-btn {
    width: 100%; /* Make buttons full-width */
    max-width: 300px; /* Restrict button width on larger screens */
  }
  
  /* Responsive styles */
  @media (min-width: 768px) {
    .button-container {
      flex-direction: row; /* Arrange buttons horizontally on larger screens */
      justify-content: center; /* Center-align buttons horizontally */
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/css/dashboard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;IACb,iBAAiB,EAAE,8CAA8C;IACjE,sBAAsB,EAAE,mEAAmE;EAC7F;;EAEA;IACE,WAAW;IACX,mBAAmB,EAAE,yCAAyC;EAChE;;EAEA;IACE,eAAe;IACf,mBAAmB;IACnB,kBAAkB,EAAE,6BAA6B;EACnD;;EAEA;IACE,aAAa;IACb,sBAAsB,EAAE,8CAA8C;IACtE,SAAS,EAAE,8BAA8B;EAC3C;;EAEA;IACE,WAAW,EAAE,4BAA4B;IACzC,gBAAgB,EAAE,4CAA4C;EAChE;;EAEA,sBAAsB;EACtB;IACE;MACE,mBAAmB,EAAE,mDAAmD;MACxE,uBAAuB,EAAE,sCAAsC;IACjE;EACF","sourcesContent":[".dashboard-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding: 20px;\n    min-height: 100vh; /* Ensure the container takes up full height */\n    box-sizing: border-box; /* Include padding and border in element's total width and height */\n  }\n  \n  .navbar {\n    width: 100%;\n    margin-bottom: 20px; /* Add space between navbar and content */\n  }\n  \n  h1 {\n    font-size: 2rem;\n    margin-bottom: 20px;\n    text-align: center; /* Center-align the heading */\n  }\n  \n  .button-container {\n    display: flex;\n    flex-direction: column; /* Stack buttons vertically on small screens */\n    gap: 10px; /* Add space between buttons */\n  }\n  \n  .button-container .ant-btn {\n    width: 100%; /* Make buttons full-width */\n    max-width: 300px; /* Restrict button width on larger screens */\n  }\n  \n  /* Responsive styles */\n  @media (min-width: 768px) {\n    .button-container {\n      flex-direction: row; /* Arrange buttons horizontally on larger screens */\n      justify-content: center; /* Center-align buttons horizontally */\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
