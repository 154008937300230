import React, { useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { Layout, Menu, Button } from "antd";
import Home from "./home";
import Login from "./pages/login";
import Dashboard from "./pages/dashboard";
import GstPurchaseReport from "./gst/gstPurchase";
import GstSalesReport from "./gst/gstSales";
import GstSalesB2CReport from "./gst/gstSalesB2C";
import Navbar from "./components/navbar";
import withAuth from "./withAuth";
import { MenuOutlined } from "@ant-design/icons";
import CorporateSales from "./pages/corporateSales";
import InvoiceSettings from "./pages/invoiceSettings";

const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;

const App = () => {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();

  const token = localStorage.getItem("token");

  const toggleCollapsed = () => setCollapsed(!collapsed);

  const ProtectedDashboard = withAuth(Dashboard);
  const ProtectedGstPurchaseReport = withAuth(GstPurchaseReport);
  const ProtectedGstSalesReport = withAuth(GstSalesReport);
  const ProtectedGstSalesB2CReport = withAuth(GstSalesB2CReport);
  const ProtectedCorporateSales = withAuth(CorporateSales);
  const ProtectedInvoiceSettings = withAuth(InvoiceSettings);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          zIndex: 1000,
          backgroundColor: "#001529",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 16px",
        }}
      >
        <div style={{ color: "white", fontSize: "25px", fontWeight: "bold" }}>
          RK FARM
        </div>
        <Navbar />
      </Header>

      <Layout style={{ marginTop: 64 }}>
        {token && (
          <Sider
            collapsible
            collapsed={collapsed}
            trigger={null}
            style={{ backgroundColor: "#001529" }}
          >
            <Menu
              theme="dark"
              mode="inline"
              defaultOpenKeys={["reports"]}
              style={{ textAlign: "left", marginTop: "20px" }}
            >
              <Menu.Item key="dashboard" onClick={() => navigate("/dashboard")}>
                Dashboard
              </Menu.Item>
              <SubMenu key="reports" title="GST Reports">
                <Menu.Item
                  key="gstPurchase"
                  onClick={() => navigate("/gst-purchase-report")}
                >
                  GST Purchase Report
                </Menu.Item>
                <Menu.Item
                  key="gstSalesB2B"
                  onClick={() => navigate("/gst-sales-b2b-report")}
                >
                  GST Sales B2B Report
                </Menu.Item>
                <Menu.Item
                  key="gstSalesB2C"
                  onClick={() => navigate("/gst-sales-b2c-report")}
                >
                  GST Sales B2C Report
                </Menu.Item>
              </SubMenu>
              <SubMenu key="sales" title="Sales">
                <Menu.Item
                  key="corporateSales"
                  onClick={() => navigate("/corporate-sales")}
                >
                  Corporate Sales
                </Menu.Item>
              </SubMenu>
              <div style={{ position: "absolute", bottom: 20, right: 20 }}>
                <Button
                  type="primary"
                  onClick={toggleCollapsed}
                  icon={<MenuOutlined />}
                  style={{
                    border: "none",
                    background: "transparent",
                    color: "#fff",
                  }}
                />
              </div>
            </Menu>
          </Sider>
        )}

        <Content
          style={{ margin: "24px 16px", padding: 24, background: "#fff" }}
        >
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/dashboard" element={<ProtectedDashboard />} />
            <Route
              path="/gst-purchase-report"
              element={<ProtectedGstPurchaseReport />}
            />
            <Route
              path="/gst-sales-b2b-report"
              element={<ProtectedGstSalesReport />}
            />
            <Route
              path="/gst-sales-b2c-report"
              element={<ProtectedGstSalesB2CReport />}
            />
            <Route
              path="/corporate-sales"
              element={<ProtectedCorporateSales />}
            />
            <Route
              path="/corporate-sales/settings"
              element={<ProtectedInvoiceSettings />}
            />
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
};

export default App;
