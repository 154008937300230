import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
  dateRange: null,
  selectedLocation: "all",
};

const corporateSalesSlice = createSlice({
  name: "corporateSales",
  initialState,
  reducers: {
    setDateRange(state, action) {
      state.dateRange = action.payload;
    },
    setSelectedLocation(state, action) {
      state.selectedLocation = action.payload;
    },
  },
});

export const { setDateRange, setSelectedLocation } =
  corporateSalesSlice.actions;
export default corporateSalesSlice.reducer;
