import axios from "axios";

const BASE_URL = "https://api.rkfarmstore.com/api";

export const fetchCorporateSales = async (startDate, endDate, orderByDate) => {
  try {
    const response = await axios.get(`${BASE_URL}/corporate-sales`, {
      params: {
        start_date: startDate,
        end_date: endDate,
        order_by_date: orderByDate,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching corporate sales data:", error.message);
    throw error;
  }
};

export const fetchBusinessLocations = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/getBusinessLocations`);
    return response.data.businessLocations || [];
  } catch (error) {
    console.error("Error fetching business locations:", error.message);
    throw error;
  }
};

export const fetchPaymentAccounts = async (id) => {
  try {
    const url = id
      ? `${BASE_URL}/getPaymentAccounts?id=${id}`
      : `${BASE_URL}/getPaymentAccounts`;
    const response = await axios.get(url);

    return response.data.paymentAccounts || [];
  } catch (error) {
    console.error("Error fetching payment accounts:", error.message);
    throw error;
  }
};

export const fetchInvoiceSettings = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/invoice-settings`);
    return response.data.invoiceSettings || [];
  } catch (error) {
    console.error("Error fetching invoice settings:", error.message);
    throw error;
  }
};

export const updateInvoiceSetting = async (id, updatedSettings) => {
  try {
    const response = await axios.put(
      `${BASE_URL}/invoice-settings/${id}`,
      updatedSettings
    );
    return response.data;
  } catch (error) {
    console.error("Error updating invoice setting:", error.message);
    throw error;
  }
};

export const fetchTaxById = async (taxId) => {
  try {
    if (taxId !== null) {
      const response = await axios.get(`${BASE_URL}/getTax/${taxId}`);
      return response.data || null;
    }
  } catch (error) {
    console.error("Error fetching tax rate:", error.message);
    throw error;
  }
};

export const fetchProductName = async (productId) => {
  try {
    const response = await axios.get(`${BASE_URL}/getProductName/${productId}`);
    return response.data.product_name || `Product ID: ${productId}`;
  } catch (error) {
    console.error(
      `Error fetching product name for ID ${productId}:`,
      error.message
    );
    throw error;
  }
};

export const login = async (email, password) => {
  try {
    const response = await axios.post(`${BASE_URL}/login`, {
      email,
      password,
    });
    return response.data; 
  } catch (error) {
    console.error("Error logging in:", error.message);
    throw error; 
  }
};

export const fetchGstSalesReport = async (startDate, endDate) => {
  try {
    const response = await axios.get(`${BASE_URL}/gst-sales-report`, {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    });
    return response; 
  } catch (error) {
    console.error("Error fetching GST sales report:", error.message);
    throw error; 
  }
};

export const fetchGstPurchaseReport = async (startDate, endDate) => {
  try {
    const response = await axios.get(`${BASE_URL}/gst-purchase-report`, {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    });
    return response; 
  } catch (error) {
    console.error("Error fetching GST purchase report:", error.message);
    throw error; 
  }
};